import React from 'react';

export interface IShowSearchContextType {
  showSearchBar: boolean;
  setVisible: (showSearchBar: boolean) => void;
  placeholder: string;
  setPlaceholder: (placeholder: string) => void;
  uniqueId: string;
  setUniqueId: (placeholder: string) => void;
}

// Allows Header to hide search bar when book is being viewed in DiscoverBook.tsx
export const ShowSearchBarContext = React.createContext<IShowSearchContextType>({
  showSearchBar: true,
  setVisible: () => {},
  placeholder: 'SearchTeams',
  setPlaceholder: () => {},
  uniqueId: '',
  setUniqueId: () => {}
});
